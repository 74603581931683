import tippy from "tippy.js";
const $ = require("jquery");

import {handleHomePaginationLinkAction} from  './home-scroll';

export function launchTooltip() {
    tippy('.--tooltip-html', {
        content(reference) {
            const id = reference.getAttribute('data-template');
            const template = document.getElementById(id);
            return template.innerHTML;
        },
        allowHTML: true,
    });
    tippy('.--tooltip-txt', {
        content(reference) {
            return reference.getAttribute('data-title');
        },
    });
}

function handleClosePopinAction()
{
    $('.custom-popin .popin-close').unbind('click').on('click', function(event) {
        event.preventDefault();
        $('.custom-popin').remove();
        $('.opacity-layer').remove();
    });
}

export function popLink() {
    $('.pop-link').unbind('click').on('click', function(event) {
        event.preventDefault();
        const url = $(this).attr('href');
        const name = Date.now();
        window.open(url, name, "width=1680,height=950");
    })
}

export function submitLinks() {
    $('.popin-content a.submitLink').click(function (event) {
        let formComment = $('#formComment');
        if ($(this).text() !== 'Autre') {
            event.preventDefault();
            let url = $(this).attr('href');
            formComment.attr('action', url);
            formComment.submit();
        } else {
            if ($('#note').val() === '') {
                $('#emptyComment').removeClass('--hidden');
                return false;
            } else {
                event.preventDefault();
                let url = $(this).attr('href');
                formComment.attr('action', url);
                formComment.submit();
            }
        }

    });
}

export function customForm() {
    $("#point_of_sale_parentPos").select2();
    customDatePicker();
}

export function customDatePicker() {
    $('input[type="date"].datepicker').prop('type', 'text');
    let $datepicker = $('.datepicker');
    $datepicker.datepicker();
    $datepicker.datepicker('option', 'dateFormat', 'dd/mm/yy');
    $('.datepicker.stroke').datepicker('option', 'dateFormat', 'dd-mm-yy');
    $('.datepicker.minToday').datepicker("option", "minDate", new Date());
    $('.datepicker.max3months').datepicker("option", "maxDate", '+3M').on('change', function() {
        const container = $(this).parents('.datetime');
        container.find('ul.errors').remove();
        const arr = $(this).val().split('/');
        const date = new Date(arr[2],arr[1]-1,arr[0]);
        if ( Object.prototype.toString.call(date) === "[object Date]" ) {
            if ($(this).hasClass('.no-message') === false) {
                return;
            }
            if ( !isNaN(date.getTime()) ) {
                let max = new Date ();
                max.setMonth(max.getMonth() + 3);
                if (date.getTime() > max.getTime()) {
                    container.prepend('<ul class="errors"><li>'+Translator.trans('crm.date.max_months', {'months': 3})+'</li></ul>');
                } else {
                    container.find('ul.errors').remove();
                }
            } else {
                container.prepend('<ul class="errors"><li>'+Translator.trans('crm.date.invalid')+'</li></ul>');
            }
        } else {
            container.prepend('<ul class="errors"><li>'+Translator.trans('crm.date.invalid')+'</li></ul>');
        }
    });
}

export function handlePopinAction()
{
    customDatePicker();
    handleClosePopinAction();
    $('.popin-content select').select2();
    let popinButton = $('.popin-button');
    popinFunctions();

    popinButton.unbind('click').click(function(e){
        $('.custom-popin').remove();
        $('.opacity-layer').remove();
        e.preventDefault();
        let url = $(this).attr('href');
        $.ajax(url)
            .done(function(response){
                $('body').append('<div class="opacity-layer"></div>');
                let html = response;
                if (response.html) {
                    html = response.html;
                }
                $('body').append('<div class="custom-popin">'+html+'</div>');
                //triggerForms(); (pour enregistrer reaffect en ajax ?)
                handlePopinAction();
                submitLinks(); // pour enregistrer la valeur du champ "commentaire" (par exemple sur la popin "classer sans suite")
                handleChangePos();
                handlePopinLeadForm($('#reaffect'));
                handlePopinLeadForm($('#lostDismiss'));
                handlePopinLeadForm($('#lostValidate'));
                if (response.url) {
                    setTimeout(function () {
                        window.location = response.url;
                    }, 2000);
                }
                handleClosePopinAction();
            });
    });
}

export function loadMultipleSelect(select) {
    if (select.length === 0) {
        return;
    }
    let selected = select.data('selected');
    if (selected !== null) {
        selected = selected.toString();
        $('#select2seller').select2('val', selected.split(',')).trigger('render');
    }
}


export function loadSellersList(select) {

    let posId = $('#select2pos').val();
    let sellerSel = $('#select2seller');
    if (sellerSel.length === 0) {
        return;
    }
    let url = $(select).data('url');
    let selected = sellerSel.data('selected');

    if (selected !== undefined) {
        selected = selected.toString().split(',');
    }

    if (posId === undefined || posId === null || posId.length === 0)
        return;
    url = url + '/' + posId;

    $.get(url, function (data) {
        sellerSel.empty();
        $.each(data, function (key, value)
        {
            let elt = $('<option value=' + key + '>' + value + '</option>');
            if (selected != null) {
                for (let i = 0; i < selected.length; i++) {
                    let seller = selected[i];
                    if (seller === key) {
                        elt.attr('selected');
                        break;
                    }
                }
            } else {
            }
            sellerSel.append(elt);
        });

        loadMultipleSelect(sellerSel);
    });
}

import moment from 'moment';

export function redirectWithParams(redirect, element, nobloc) {

    redirect = typeof redirect !== 'undefined' ? redirect : true;
    nobloc = typeof nobloc !== 'undefined' ? nobloc : false;

    let data = [];
    const elementsToProcess = [
        { id: '#select2seller', variable: 'seller' },
        { id: '#select2pos', variable: 'pos' },
        { id: '#selectOperator', variable: 'operator' },
        { id: '#channel', variable: 'channel' },
        { id: '#interest', variable: 'processus' },
        { id: '#search_keywords', variable: 'search_keywords' },
        { id: '#channel', variable: 'channel' },
        { id: '#originFilter', variable: 'origin' },
        { id: '#evolution-range', variable: 'evolution-range' },
        { id: '#references', variable: 'references' },
        { id: '#libelles', variable: 'libelle' },
    ];

    for (let i = 0; i < elementsToProcess.length; i++) {
        let elementInfo = elementsToProcess[i];
        let select = $(elementInfo.id);
        let elementValue = select.val();
        let value = '';

        if (select.prop('multiple')) {
            if (elementValue != null && $.isArray(elementValue)) {
                value = elementValue;
                if (value.length === 0) {
                    value = 0;
                }
            }
        } else {
            value = elementValue;
        }
        if (typeof value != 'undefined') {
            data[elementInfo.variable] = value === null ? '' : value;
        }
    }

    let start = null;
    let end =  null;
    let range = null;
    let filterRange = $("#filter-range");

    let rangePicker = filterRange.daterangepicker('getRange');
    if (rangePicker != null) {
        start = moment(filterRange.daterangepicker('getRange').start).format('DD-MM-YYYY');
        end = moment(filterRange.daterangepicker('getRange').end).format('DD-MM-YYYY');
        range = 'custom';
    }
    if (nobloc === false) {
        let bloc = $('#idBlock').val();
    }

    if (typeof element != 'undefined') {
        let sortOrder = element.attr('data-sort-order');
        let sortColumn = element.attr('data-sort-column');
    }
    let route = $('#contentFilter .route').val();

    if (typeof seller !== 'undefined') {
        //on ne garde pas l'info sur le vendeur si c'est le pos qu'on change
        if (typeof element == 'undefined' || element.hasClass('pos') === false) {
            data['seller'] = seller;
        }
    }


    if (nobloc === false && typeof bloc != 'undefined' && bloc !== '') {
        data['bloc'] = bloc;
    }
    if (typeof sortOrder !== 'undefined' && sortOrder !== '') {
        data['orderby'] = sortOrder;
    }
    if (typeof sortColumn !== 'undefined' && sortColumn !== '') {
        data['sort'] = sortColumn;
    }
    if (range != null) {
        data['dbrange'] = range;
    }
    if (start != null && typeof start !== 'undefined' && start !== '') {
        data['start'] = start;
    }
    if (end != null && typeof end !== 'undefined' && end !== '') {
        data['end'] = end;
    }
    if (redirect) {
        window.location = Routing.generate(route, data);
    }
    else {
        return Routing.generate(route, data);
    }
}


function updateSellersByPos(pos, sellers, except) {
    let interest = $('#leadInterest').val();
    $.get(Routing.generate('get_sellers_by_pos', {
        'pos': pos,
        'interest': interest,
        'except': except
    }), function (data) {
        sellers.empty();
        let option = '';
        $.each(data, function(key, value) {
            option = $("<option></option>").val(key).text(value);
            sellers.append(option);
        });
        sellers.trigger('destroy');
        sellers.select2();
    });
}

export function handleChangePos() {
    let lostDismissLeadOwnerGroup = $('#lost_dismiss_lead_ownerGroup');
    if (lostDismissLeadOwnerGroup.length > 0) {
        updateSellersByPos(lostDismissLeadOwnerGroup.val(), $("#lost_dismiss_lead_seller"));
        lostDismissLeadOwnerGroup.on('change', function() {
            updateSellersByPos($(this).val(), $("#lost_dismiss_lead_seller"));
        })
    }
    let leadOwnerGroup = $('#lead_ownerGroup');
    if ($('.can-change #lead_ownerGroup').length > 0) {
        let except = $('#assignment_lead_seller').val();
        updateSellersByPos(leadOwnerGroup.val(), $('#lead_seller'), except);
        leadOwnerGroup.on('change', function() {
            updateSellersByPos($(this).val(), $('#lead_seller'), except);
        })
    }
    if ($('.can-change #assignment_lead_ownerGroup').length > 0) {
        let except = $('#assignment_lead_currentSeller').val();
        let assignmentLeadOwnerGroup = $('#assignment_lead_ownerGroup');
        updateSellersByPos(assignmentLeadOwnerGroup.val(), $('#assignment_lead_seller'), except);
        assignmentLeadOwnerGroup.on('change', function() {
            updateSellersByPos($(this).val(), $('#assignment_lead_seller'), except);
        })
    }
}

function validateEditHourAction() {

    let validateEditHourButton = $('#editHour').find('.--confirm');
    validateEditHourButton.click(() => {
        let form = $('#editHour');
        let url = form.attr('action');
        let formSerialize = form.serialize();

        $.post(url, formSerialize, function (response) {
            if (response === 'success') {
                location.reload();
            } else {
                $('#hour').replaceWith(response);
                validateEditHourAction();
            }
        })
            .fail(function (error) {
                console.log(error);
            })
        ;
    });
}

function popinFunctions() {
    validateEditHourAction();
    preventDoublon();
}

export function updateSellers()
{
    let pos = $('#lead_pos').val();
    let leadTeam = $( "#lead_team" );
    if (leadTeam.length <= 0) {
        return;
    }
    let interest = $('#lead_interest input:checked').val();
    if (typeof interest !== 'undefined') {

        //show loader
        leadTeam.find('.common-loader').removeClass('--hidden');
        leadTeam.find('.section-content .sellers').html('')

        let url = Routing.generate('lead_sellers_list', {'id': pos, 'interestCode': interest});

        $.get( url, function( data ) {
            leadTeam.replaceWith( data );
            $('#selectPos').select2();
            sortingSellersHandler();
            // Seller list auto-selection if user fill reference or zipcode field
            autoSelectSeller();
            if ($('#value_lead_id').val() !== "") {
                preSelectSellerLine();
            }
            let sellerLine = $('.seller-line');
            if (sellerLine.length === 1) {
                toggleSellerLineSelection(sellerLine);
            }
            // si pas de vendeur sélectionné on déselectionne dans le select
            if ($('.seller-line.seller-selected').length === 0) {
                $('#lead_seller').find('option:selected').prop('selected', false);
            }
            launchTooltip();
            //hide loader
            leadTeam.find('.common-loader').addClass('--hidden');
        });
    } else {
        // remove sellers
        leadTeam.find('.section-content').html('<div class="interest-empty empty-data">' + Translator.trans('crm.lead.form.no_interest') + '</div>');
    }
}

export function sortingSellersHandler()
{
    const sortButtons = $('.sellers .actions span');
    sortButtons.on('click', function() {
        sortButtons.removeClass('--current');
        $(this).addClass('--current');
        const direction = $(this).data('direction');
        const container = $('.sellers .content');
        const searchable = $(this).data('col');
        $('.seller-line').sort(function (a, b) {
            const contentA = $(a).find('.seller-line__' + searchable).data('sort');
            const contentB = $(b).find('.seller-line__' + searchable).data('sort');
            if (direction == 'asc') {
                return (contentA < contentB) ? -1 : (contentA > contentB) ? 1 : 0;
            } else {
                return (contentB < contentA) ? -1 : (contentB > contentA) ? 1 : 0;

            }
        }).appendTo(container);
    })
}

export function autoSelectSeller()
{

    const sellers = $('.sellers .content');


    const assignmentType = $('.sellers').data('assignment');
    if (assignmentType === 'prioritization') {
        $('[data-col="position"][data-direction="asc"]').trigger('click');
    } else {
        $('[data-col="last_lead"][data-direction="asc"]').trigger('click');
    }
    setTimeout(function() {
        // les absents doivent être en bas de liste sauf pour la prioritization
        if (assignmentType !== 'prioritization' || $('.sellers').hasClass('--group') === false) {
            $('.seller-line.absent:not(.seller-selected)').appendTo(sellers);
        } else {
            // pour la prioritization dans le cas d'un groupe,
            // on met les sellers absents du groupe avant les sellers de PDV
            let lastGroupSeller = $('.seller-line.--with-position:last');
            lastGroupSeller.addClass('cocuou');
            $('.seller-line.absent.--with-position:not(.seller-selected)').insertAfter(lastGroupSeller);
            $('.seller-line.absent:not(.--with-position):not(.seller-selected)').appendTo(sellers);
        }
        setTimeout(function() {
            // si un vendeur est déjà sélectionné, le mettre en haut
            $('.seller-line.seller-selected').prependTo(sellers);
        }, 5);
    }, 50);


    if (typeof pageType !== typeof undefined) {
        if (pageType === "mail" || pageType === "new") {

            const leadZip = $('.lead-zip');
            const leadOrigin = $('#lead_origin');
            const propertyReference = $('.property-reference').find('input');
            propertyReference.unbind('input');
            const assignmentType = $('.sellers').data('assignment');
            if (assignmentType === 'zipcode') {
                autoSelectSellerByZipcode(leadZip.val());
                leadZip.change(function () {
                    autoSelectSellerByZipcode($(this).val());
                });
            }
            else if (assignmentType === 'origin') {
                autoSelectSellerByOrigin(leadOrigin.find('option:selected').text());
                leadOrigin.change(function () {
                    autoSelectSellerByOrigin($(this).find('option:selected').text());
                });
            }
            else if (assignmentType === 'affectcode') {
                autoSelectSellerByAffectcode(propertyReference.val());
                propertyReference.on('input', function () {
                    autoSelectSellerByAffectcode($(this).val());
                });
            }
            else if (assignmentType === 'inturn') {
                // trier par date décroissante
                autoSelectSellerByInturn();
            }
            else if (assignmentType === 'prioritization') {
                autoSelectSellerByPrioritization();
            }
        }
    }
}

export function preSelectSellerLine()
{
    let chosenSellerId = parseInt($('#lead_seller').find('option:selected').val());
    let posId = parseInt($('#lead_ownerGroup').find('option:selected').val());
    if (chosenSellerId != null) {
        $('.seller-line').each(function(){
            if ( $(this).data("id") === chosenSellerId && $(this).data("pos") == posId ) {
                toggleSellerLineSelection($(this));
            }
        })
    }
}

function disableFields(field)
{
    let sellerLine = field.closest('.seller-line');

    $('#lead_seller').find('option:selected').prop('selected', false);

    field.prop('checked', false);
    sellerLine.removeClass("seller-selected");
}
function selectLeadPos()
{
    let sellerPosId = $('#selectPos').find('option:selected').val();
    $('#lead_ownerGroup option[value=' + sellerPosId + ']').prop('selected', true);
}

export function toggleSellerLineSelection(select, move) {
    let checkbox = select.find(".select-lead-seller-checkbox");
    checkbox.trigger('click');

    let sellerLine = select.closest('.seller-line');
    let chosenSellerId = sellerLine.data('id');
    let sellerPos = sellerLine.data('pos')

    // Disable previous seller selected
    $('.select-lead-seller-checkbox').each(function(){
        let sellerLineIdValue = $(this).closest('.seller-line').data('id');
        let sellerLinePosValue = $(this).closest('.seller-line').data('pos')
        if (
            ( sellerLineIdValue !== chosenSellerId ) ||
            ( sellerLineIdValue === chosenSellerId && sellerLinePosValue !== sellerPos )
        ) {
            disableFields($(this));
        }
    });
    $('#lead_seller option[value=' + chosenSellerId + ']').prop('selected', true);
    $('#lead_ownerGroup option[value=' + sellerPos + ']').prop('selected', true);
    sellerLine.addClass("seller-selected");
    if (typeof move !== 'undefined' && move) {
        sellerLine.prependTo(".sellers .content");
    }
    selectLeadPos();
    if (sellerId != chosenSellerId && sellerLine.hasClass('absent')) {
        sellerPresent = false;
        sellerIdentity = sellerLine.find('.seller-identity').text();
    } else {
        sellerPresent = true;
    }

}

export function toggleSectionContentVisibility()
{
    $('.toggle-section-visibility').unbind('click').click(function() {
        const sectionContent = $(this).closest('header').siblings('.section-content');

        if ($(this).hasClass('--close')) {
            $(this).removeClass('--close');
            sectionContent.removeClass('--hidden');
            if ($(this).hasClass('--stats')) {
                setCookie("statsFilterClosed", 0, 7);
            }
            $(this).parents('.section').find('#transferRate2').addClass('--hidden');
        } else {
            $(this).addClass('--close');
            sectionContent.addClass('--hidden');
            if ($(this).hasClass('--stats')) {
                setCookie("statsFilterClosed", 1, 7);
            }
            $(this).parents('.section').find('#transferRate2').removeClass('--hidden');
        }
    });

    $('.section-content.--mobile-hidden').each(function() {
        const toggleButton = $(this).parents('.section').find('.toggle-section-visibility');

        if (window.matchMedia('(max-width: 1024px)').matches) {
            $(this).addClass('--hidden').removeClass('--mobile-hidden');
            toggleButton.addClass('--close').removeClass('--mobile-close');
        }
    });
}

function autoSelectSellerByZipcode(zipcode) {
    //check univers (car plus de unbind)
    const assignmentType = $('.sellers').data('assignment');
    if (assignmentType !== 'zipcode') {
        return;
    }
    if (zipcode === '') {
        return;
    }
    let sellerLine = $('.seller-line');
    let lastSellerAssignLeadDate = new Date();
    let sellerToSelect;

    sellerLine.each(function () {
        let sellerLineZipcode = $(this).find('.seller-sector').text().replace(/\s+/g, '');
        if (sellerLineZipcode.includes(zipcode)) {
            let sellerAssignLeadDate = $(this).find('.seller-last-assign-lead-date').text().trim();
            let numbers = sellerAssignLeadDate.match(/\d+/g);
            // si pas de date, on l'assigne en priorité
            if (numbers === null) {
                sellerToSelect = $(this);
                return false;
            }
            let date = new Date(numbers[2], numbers[1]-1, numbers[0], numbers[3], numbers[4]);
            if (date < lastSellerAssignLeadDate) {
                sellerToSelect = $(this);
                return false;
            }
        }
    });
    if (typeof sellerToSelect !== 'undefined') {
        toggleSellerLineSelection(sellerToSelect, true);
    }
}
function autoSelectSellerByOrigin(origin) {
    //check univers (car plus de unbind)
    const assignmentType = $('.sellers').data('assignment');
    if (assignmentType !== 'origin') {
        return;
    }
    if (origin === '') {
        return;
    }
    let sellerLine = $('.seller-line');
    let lastSellerAssignLeadDate = new Date();
    let sellerToSelect;

    sellerLine.each(function () {
        let sellerLineOrigin = $(this).find('.seller-origin').text();
        if (sellerLineOrigin.includes(origin)) {
            let sellerAssignLeadDate = $(this).find('.seller-last-assign-lead-date').text().trim();
            let numbers = sellerAssignLeadDate.match(/\d+/g);
            // si pas de date, on l'assigne en priorité
            if (numbers === null) {
                sellerToSelect = $(this);
                return false;
            }
            let date = new Date(numbers[2], numbers[1]-1, numbers[0], numbers[3], numbers[4]);
            if (date < lastSellerAssignLeadDate) {
                sellerToSelect = $(this);
                return false;
            }
        }
    });
    if (typeof sellerToSelect !== 'undefined') {
        toggleSellerLineSelection(sellerToSelect, true);
    }
}

function autoSelectSellerByAffectcode(reference) {
    if (reference === '') {
        return;
    }

    let bestMatch = null;
    let bestMatchLength = 0;

    $('.seller-line').each(function () {
        let sellerLineReference = $(this).find('.seller-affect-code').text().replace(/\s+/g, '');
        if (reference.toUpperCase().indexOf(sellerLineReference.toUpperCase()) !== -1) {
            if (sellerLineReference.length > bestMatchLength) {
                bestMatch = $(this);
                bestMatchLength = sellerLineReference.length;
            }
        }
    });

    if (bestMatch) {
        toggleSellerLineSelection(bestMatch, true);
    }
}

function autoSelectSellerByInturn() {
    toggleSellerLineSelection($('.seller-line:first'));
    return false;
}
function autoSelectSellerByPrioritization() {
    toggleSellerLineSelection($('.seller-line:not(.absent):first'));
    return false;
}

let listEmailsIds = [];
export function setListeEmailsIds()
{
    $('.tableLine').each(function(){
        let emailLineId = $(this).find('.select-line').data('id');
        listEmailsIds.push(emailLineId);
    })
}

export function updateEmailLineInfoOperator()
{
    if (listEmailsIds.length && typeof getEmailOperatorInfoUrl !== 'undefined') {
        let payload = JSON.stringify({listEmailsIds});

        $.post(getEmailOperatorInfoUrl, payload)
            .done(function(emailsOperatorInfo){
                $.each(emailsOperatorInfo, function(emailId, operatorAlias){
                    let emailLineCheckbox = $("input[data-id='" + emailId +"']");
                    let emailLine = emailLineCheckbox.closest('.tableLine');
                    let emailLineInfoOperatorElement = emailLine.find('.tableLine-alias');
                    emailLineInfoOperatorElement.html(operatorAlias)
                })
            });
    }
}

export function updateCallLineInfoOperator()
{
    if (typeof listCallsIds !== 'undefined' && listCallsIds.length && typeof getCallOperatorInfoUrl !== 'undefined') {
        let payload = JSON.stringify({listCallsIds});

        $.post(getCallOperatorInfoUrl, payload)
            .done(function(callsOperatorInfo){
                $.each(callsOperatorInfo, function(callId, operatorAlias){
                    let callLineCheckbox = $("input[data-id='" + callId +"']");
                    let callLine = callLineCheckbox.closest('.tableLine');
                    let callLineInfoOperatorElement = callLine.find('.call-info-operator p');
                    callLineInfoOperatorElement.html(operatorAlias)
                })
            });
    }
}

export function validateLeadAnonymization()
{
    let validateLeadAnonymizationAction = $('.validate-anonymization');
    validateLeadAnonymizationAction.unbind('click').click(function(e){
        e.preventDefault();
        let url = $(this).data('url');
        let leadId = $(this).data('id');
        openConfirmation({'title': Translator.trans('crm.common.confirmation'), 'confirmText': Translator.trans('crm.common.validate'), 'text': Translator.trans('crm.common.confirm_anonymize_lead', {'id': leadId, 'customer': $(this).attr('data-customer')})});
        $('.pop-confirmation .confirm').on('click', function() {
            $.get(url).done(function(response) {
                $('.leads-table').find('tr[data-id="'+leadId+'"]').remove();
                $('.js-flash-message-success .text').html(response.message);
                $('.js-flash-message-success').removeClass('--hidden');
                setTimeout(function() {
                    $('.js-flash-message-success').addClass('--hidden');
                }, 15000);
            });
        });
    });
}


// refresh operator names in JDA list
let listCallsIds = [];
export function setListCallsIds()
{
    if ($('.jda-table').length > 0) {
        $('.tableLine').each(function(){
            let callLineId = $(this).find('.select-line').data('id');
            listCallsIds.push(callLineId);
        })
    }
}


export function openConfirmation(params)
{
    const popOverlay = $('body > .pop-overlay');
    const popConfirmation = $('body > .pop-confirmation');
    popConfirmation.removeClass();
    popConfirmation.addClass('pop-confirmation');
    popConfirmation.addClass('section');
    if (params.class) {
        popConfirmation.addClass(params.class);
    }
    popOverlay.removeClass('--hidden');
    popConfirmation.find('.text').html(params.text);
    if (params.element) {
        popConfirmation.html(params.element.html());
    } else if (params.content) {
        popConfirmation.find('.text .common-loader').removeClass('--hidden');
        $.get(params.content, function (data) {
            popConfirmation.find('.text').html(data);
            if (params.afterOpen) {
                params.afterOpen();
            }
        });
    } else {
        if (params.title) {
            popConfirmation.find('h3').html(params.title).removeClass('--hidden');
        } else {
            popConfirmation.find('h3').html('').addClass('--hidden');
        }
        if (params.cancelText) {
            popConfirmation.find('.cancel').html(params.cancelText);
        }
    }
    if (params.confirmText) {
        popConfirmation.find('.confirm').html(params.confirmText);
    }

    if (params.alert) {
        popConfirmation.find('.cancel').addClass('--hidden');
        // si c'est une alerte, on affiche uniquement le bouton "confirmer" qui sert à fermer la pop
        popConfirmation.find('.confirm').unbind('click').on('click', function(event) {
            event.preventDefault();
            popOverlay.addClass('--hidden');
            popConfirmation.addClass('--hidden');
        });
    } else {
        popConfirmation.find('.cancel').removeClass('--hidden');
        // sinon on affiche "confirmer" et annuler". "annuler" sert à fermer la pop
        popConfirmation.find('.confirm').unbind('click').on('click', function(event) {
            event.preventDefault();
            if (params.redirectUrl) {
                window.location = params.redirectUrl;
            }
            else if (params.form) {
                if (params.data) {
                    // si c'est un formulaire, confirmer sert à submit le form (en modifiant la variable qui bloquait le submit)
                    params.data.valueOf = params.data.toSource = params.data.toString = function(){ return true; };
                }
                params.form.submit();
            } else if (params.action) {
                params.action(params.param);
            } else if (params.button) {
                params.button.attr('data-popin', 1);
                params.button.trigger('click');
            } else {
                // sinon, "confirmer" sert à rediriger vers l'url initiale
                popConfirmation.find('.confirm').attr('data-url', params.url);
            }
            if (!params.keepOpenOnConfirm) {
                popOverlay.addClass('--hidden');
                popConfirmation.addClass('--hidden');
            }
            if (params.afterClose) {
                params.afterClose();
            }
        });
        popConfirmation.find('.cancel').unbind('click').on('click', function(event) {
            event.preventDefault();
            popOverlay.addClass('--hidden');
            popConfirmation.addClass('--hidden');
            if (params.afterClose) {
                params.afterClose();
            }
        });
    }
    popConfirmation.removeClass('--hidden');
}

export function handleSendReminder() {
    $('.send-reminder').unbind('click').on('click', function(event) {
        event.preventDefault();
        $(this).addClass('--disabled');
        $.get($(this).attr('href'), function (data) {
            if (data.ok === true) {
                openConfirmation({'text': data.message, 'confirmText': Translator.trans('crm.common.close'), 'alert': true});
            } else {
                openConfirmation({'text': data.message, 'confirmText': Translator.trans('crm.common.close'), 'alert': true});
            }
        }).fail(function() {
            openConfirmation({'text': Translator.trans('crm.reminder.not_sent'), 'confirmText': Translator.trans('crm.common.close'), 'alert': true});
        })
    })
}
function handlePopinLeadForm(element) {
    if (element.length === 0) {
        return;
    }
    element.find('form').on('submit', function(event) {
        event.preventDefault();
        $.ajax({
            url: $(this).attr('action'),
            type: $(this).attr('method'),
            data: $(this).serialize(),
        })
        .done(function( data ) {
            if (data.url) {
                window.location.href = data.url;
            }
            if (data.result === 'success') {
                if (data.reload) {
                    location.reload();
                    return false;
                }
                $('.custom-popin').remove();
                $('.opacity-layer').remove();
                openConfirmation({'text': data.message, 'confirmText': Translator.trans('crm.common.close'), 'alert': true});
                // reaffectation : on met à jour le seller et le pdv
                if (element.attr('id') === 'reaffect') {
                    if ($('.leads-table').length > 0) {
                        // update lead
                        $('.leads-table tr[data-id='+data.id+']').find('.pos-seller').html(data.pos + '<span class="seller-alias">'+data.seller+'</span>');
                    }
                    const listNoteWrapper = $('.lead-note-form .lead-note-list-wrapper');
                    if (listNoteWrapper.length > 0) {
                        const loaderWrapper = $('.lead-note-form .common-loader');
                        // update notes
                        listNoteWrapper.html('');
                        loaderWrapper.removeClass('--hidden');
                        $.get( Routing.generate('notes_list', {'id': data.id}))
                            .done(function( response ) {
                                listNoteWrapper.html(response.template);
                                loaderWrapper.addClass('--hidden');
                            });
                    }
                } else {
                    //sinon on reloade
                    if ($('.leads-table').length > 0) {
                        let url = window.location.href;
                        let pageNb = $('#currentPage').val();
                        // si on supprime le dernier de la liste et qu'on est pas sur la page 1
                        if (pageNb > 1 && $('.leads-table table tr').length <= 2)  {
                            pageNb = pageNb - 1;
                        }
                        let pageParam = 'page=' + pageNb;
                        url = url + (url.includes('?') ? '&' : '?') + pageParam;
                        $.get(url)
                            .done(function( response ) {
                                $('.leads-table').html(response);
                                handleHomePaginationLinkAction();
                                handlePopinAction();
                            });
                    }
                }
            } else {
                element.replaceWith(data);
                handlePopinAction();
                handleChangePos();
                handlePopinLeadForm($('#reaffect'));
                handlePopinLeadForm($('#lostDismiss'));
                handlePopinLeadForm($('#lostValidate'));
            }
        })
        .fail(function( data ) {
            console.log(data);
        })
    })
}

function preventDoublon()
{
    $('form.preventDoublon').unbind('submit').submit(function() {
        //block multiple submit
        if (typeof $(this).data('blocked') == "undefined" || $(this).data('blocked') === false) {
            $(this).data('blocked',true);
            return true;
        } else {
            return false;
        }
    });
}

export function checkPhoneFields(prefix)
{
    let favoritePhoneNumberValue = $('#'+prefix+'_favoritePhoneNumber').is(':checked');
    let phoneNumberInput = $('#'+prefix+'_phoneNumber');
    let mobilePhoneNumberInput = $('#'+prefix+'_mobilePhoneNumber');
    $('.phone-error').html('');

    if (favoritePhoneNumberValue === false && phoneNumberInput.val().length === 0) {
        phoneNumberInput.siblings('.phone-error').html('Champ obligatoire');
        window.scrollTo(0, 0);
        return false;
    }
    if (favoritePhoneNumberValue === true && mobilePhoneNumberInput.val().length === 0) {
        mobilePhoneNumberInput.siblings('.phone-error').html('Champ obligatoire');
        window.scrollTo(0, 0);
        return false;
    }

    return true;
}

export function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

$(function(){
    toggleSectionContentVisibility();
    preventDoublon();
    launchTooltip();
})

